import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./ProfessorHome.css";

const ProfessorEdit2 = () => {
    const availabilitySettings = [
        {
            day: 'Monday',
            available: true,
            physicalConsultation: false,
            startTime: '12:00',
            endTime: '16:00',
        },
        {
            day: 'Tuesday',
            available: true,
            physicalConsultation: false,
            startTime: '13:00',
            endTime: '15:00',
        },
        {
            day: 'Wednesday',
            available: false,
            physicalConsultation: false,
        },
        {
            day: 'Thursday',
            available: false,
            physicalConsultation: false,
        },
        {
            day: 'Friday',
            available: true,
            physicalConsultation: false,
            startTime: '10:00',
            endTime: '16:00',
        },
    ];
    
    const navigate = useNavigate();
    const handleAddButtonClick = () => {
        // Implement the action you want to perform when the "+" button is clicked.
        // This could include opening a form, adding a new row to the table, etc.
    };

    const manageSettings = () => {
        // Implement the action for Button 3 here
        navigate('/professoredit');
    };
    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/professorhome" className="logo-button">
                        <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                    </a>
                    <a href="/professorhome" className="header-button">Home</a>
                    <span className="separator"></span>
                    <a href="/professorsettings" className="header-button">Settings Management</a>
                    <span className="separator"></span>
                    <a href="link-to-faq-page" className="header-button">FAQ</a>

                    <p className="professor-name">Professor X</p>
                    <div className="logout-btn">
                        <button class="logout-btn" onClick={handleAddButtonClick}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="sub-header">
                <h1>Manage Settings</h1>
                <div className="edit-button">
                    <button className="edit-button" onClick={manageSettings}>Manage Settings</button>
                </div>
            </div>
            <div className="content">
                <div className="form-container">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Availability</th>
                                <th>Consultation Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {availabilitySettings.map((setting, index) => (
                                <tr key={index}>
                                    <td>{setting.day}</td>
                                    <td>{setting.available ? 'Available' : 'Not Available'}</td>
                                    <td>
                                        {setting.available
                                            ? `${setting.startTime} to ${setting.endTime}`
                                            : 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProfessorEdit2;