import React, { useState } from 'react';
import './Consultation.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import BookingConfirmation from './BookingSuccess'; // Import the confirmation component

const Consultation = () => {
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  const navigate = useNavigate();

  const handleProfessorChange = (event) => {
    setSelectedProfessor(event.target.value);
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleConsultationClick = () => {
    // Add date and time validation logic here

    // If the date and time are valid, proceed to the next page (e.g., studentsettings)
    navigate('/studentsettings');
  };

  const cancelForm = () => {
    navigate('/studenthome');
  };

  const handleClearForm = () => {
    // Reset the form fields to their initial values
    setSelectedProfessor('');
    setSelectedMethod('');
    setSelectedTime('');
    setSelectedDay('');
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting and refreshing the page

    // Handle form submission and move to the next page. Send props over
    console.log('Form submitted with the following data:');
    console.log('Professor:', selectedProfessor);
    console.log('Method:', selectedMethod);
    console.log('Time:', selectedTime);
    console.log('Day:', selectedDay);

    // Show the confirmation page
    setShowConfirmation(true);

  };

  return (
    <div>
      {/* <div className="header">
        <div className="header-content">
          <a href="/studenthome" className="header-button">
            Home
          </a>
          <a href="studentfaq" className="header-button">
            FAQ
          </a>
          <p className="student-name">Student Y</p>
          <div className="logout-button">
            <button className="logout-button" onClick={handleConsultationClick}>
              Logout
            </button>
          </div>
        </div>
      </div> */}
      <h1 class="sub-header">Consultation Booking</h1>
      {showConfirmation ? (
        <BookingConfirmation
          selectedProfessor={selectedProfessor}
          selectedMethod={selectedMethod}
          selectedDay={selectedDay}
          selectedTime={selectedTime}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Professor:</label>
            <select value={selectedProfessor} onChange={handleProfessorChange} required>
              <option value="">Select a professor</option>
              <option value="Professor X">Professor X</option>
            </select>
          </div>
          <div>
            <label>Consultation Method:</label>
            <select value={selectedMethod} onChange={handleMethodChange} required>
              <option value="">Select a method</option>
              <option value="Physical">Physical</option>
              <option value="Virtual">Virtual</option>
            </select>
          </div>
          <div>
            <label>Day:</label>
            <select value={selectedDay} onChange={handleDayChange} required>
              <option value="">Select a Day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday" disabled>Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
            </select>
          </div>
          <div>
            <label>Time:</label>
            <select value={selectedTime} onChange={handleTimeChange} required>
              <option value="">Select Timing</option>
              <option value="12:00 - 12:30">12:00 - 12:30</option>
              <option value="12:30 - 13:00" disabled>12:30 - 13:00</option>
              <option value="13:00 - 13:30">13:00 - 13:30</option>
              <option value="13:30 - 14:00">13:30 - 14:00</option>
              <option value="14:00 - 14:30">14:00 - 14:30</option>
              <option value="14:30 - 15:00" disabled>14:30 - 15:00</option>
              <option value="15:00 - 15:30">15:00 - 15:30</option>
              <option value="15:30 - 16:00">15:30 - 16:00</option>
            </select>
          </div>
  
          <br/>
          <div className='button-container' style={{flexDirection:"row-reverse"}}>
            <button type="submit">Book</button>
            <button type="button" onClick={handleClearForm}>
              Clear Form
            </button>

            <button type="button" id="close" onClick={cancelForm}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Consultation;
