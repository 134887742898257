import React, { useState } from "react";
import { useAuth } from "../components/Authentication";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';

import "./Login.css";
import { Alert, Checkbox } from "@mui/material";

const Login = () => {
  const { authed, login } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();
  if (location.state){
    setTimeout(() => {
      location.state.message = '';
    }, 3000); // 3000 milliseconds (3 seconds)
  }

  console.log(location.state);
  // Use state to capture the username and password
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      // Perform any necessary validation here (e.g., check for empty fields)
      if (!username || !password) {
        setFeedbackMessage("Please enter a username and password.");
      } else {
        const response = await fetch(
          "https://inf2002-team06-2023-api.onrender.com/api/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
          }
        );

        if (response.status === 200) {
          // Login successful, you can redirect or handle it here
          const data = await response.json();
          const token = data.token;
          const decodedToken = jwt_decode(token);
          const userRole = decodedToken.role;
          login(rememberMe, token);
          if (String(userRole) === "Professor"){
            navigate("/professorhome"); // Redirect to the home page upon successful login
          }else if(String(userRole) === "Student"){
            navigate("/studenthome"); // Redirect to the home page upon successful login
          } else {
            setFeedbackMessage("Role not found.");
          }
        } else if (response.status === 401) {
          setFeedbackMessage("Wrong username/password.");
        } else if (response.status === 500) {
          setFeedbackMessage("An 500 error occurred while logging in.");
        } else {
          // Handle other status codes or errors
          setFeedbackMessage("An error occurred while logging in.");
        }
      }
    } catch (error) {
      setFeedbackMessage("Failed to login");
      console.error(error);
    }
  };

  return authed === true ? (
    <Navigate to="/home" replace />
  ) : (
    <div className="Login">
      <header className="Login-header">
        <img src="/SIT_logo.png" alt="Your Logo" className="logo-image" style={{width:"9vw"}}/>
        <p><b>Sign in with your organizational account</b></p>
        <form style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <div className="username" >
            <label id="Username" htmlFor="Username" style={{fontWeight:"400", float:"left", marginBottom:0}}>
              Username{" "}
            </label>
            <input
              type="text"
              id="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{backgroundColor:"white", width:"100%"}}
            />
          </div>
          <div className="password">
            <label id="Password" htmlFor="Password" style={{fontWeight:"400", float:"left", marginBottom:0}}>
              Password{" "}
            </label>
            <input
              type="password"
              id="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{backgroundColor:"white", width:"100%"}}
            />
          </div>
          <br></br>
          <div className="remember-me-container" style={{display:"flex", flexDirection:"row", flexWrap:"nowrap", alignItems:"center",verticalAlign:"middle", justifyContent:"flex-start"}}>
            <Checkbox
            id="RememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            style={{float:"left"}}/>
            <label htmlFor="RememberMe" style={{fontWeight:"400", float:"left"}}>Remember me</label>
          </div>
          <br></br>
          <Button variant="contained" size="large" className="login-button" onClick={handleLogin} style={{textAlign:"center !important"}}>
            Sign in
          </Button>
        </form>
        <br></br>
        {location.state && location.state.message && (
          <Alert severity="success">{location.state.message}</Alert>
        )}
        {feedbackMessage && <Alert severity="error">{feedbackMessage}</Alert>}
      </header>
    </div>
  );
};

export default Login;