import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";

import Login from "./pages/Login";
import StudentHome from "./pages/StudentHome";
import StudentHome2 from "./pages/StudentHome2";
import ProfessorHome from "./pages/ProfessorHome";
import ProfessorHome2 from "./pages/ProfessorHome2";
import ProfessorSettings from "./pages/ProfessorSettings";
import ProfessorSettings2 from "./pages/ProfessorSettings2";
import ProfessorEdit from "./pages/ProfessorEdits";
import Consultation from "./pages/Consultation";
import BookingSuccess from "./pages/BookingSuccess";
import Logout from "./pages/Logout";
import Resource from "./pages/Resource";
import ProfessorFAQ from "./pages/ProfessorFAQ";
import StudentFAQ from "./pages/StudentFAQ";
import Help from "./pages/Help"

import { ProtectedLayout } from "./components/ProtectedLayout";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./components/Authentication";

import reportWebVitals from "./reportWebVitals";

export default function App() {
  return (
    <Routes>
      <Route element={<ProtectedLayout />}>
        <Route path="studenthome" element={<StudentHome />} />
        <Route path="studenthome2" element={<StudentHome2 />} />
        <Route path="studentfaq" element={<StudentFAQ />} />
        <Route path="professorfaq" element={<ProfessorFAQ />} />
        <Route path="consultation" element={<Consultation />} />
        <Route path="resource" element={<Resource />} />

        <Route path="professorhome" element={<ProfessorHome />} />
        <Route path="professorhome2" element={<ProfessorHome2 />} />
        <Route path="/professorsettings" element={<ProfessorSettings />} />
        <Route path="/professorsettings2" element={<ProfessorSettings2 />} />

        <Route path="help" element={<Help/>} />
      </Route>
        <Route path="bookingsuccess" element={<BookingSuccess />} />
        <Route path="/professoredit" element={<ProfessorEdit />} />

        <Route path="logout" element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Login />} />
    </Routes>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

