import React, { useState, useRef } from 'react';
import { Tab, Tabs, TabList } from 'react-tabs';
import { useNavigate } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

// import "./ProfessorHome.css";

const ProfessorHome = () => {
    const navigate = useNavigate();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const currentConsultation = [
        {
            date: '05-11-2023',
            time: '14:00',
            student: 'Student A',
            venue: 'Zoom',
            description: 'For HCI Module',
        },
        {
            date: '10-11-2023',
            time: '14:00',
            student: 'Student B',
            venue: 'NYP@SIT SR3B',
            description: 'For HCI Project',
        },
        {
            date: '15-11-2023',
            time: '12:00',
            student: 'Student C',
            venue: 'NYP@SIT SR2A',
            description: 'For HCI Meeting',
        },
        {
            date: '15-11-2023',
            time: '14:00',
            student: 'Student D',
            venue: 'Zoom',
            description: 'For HCI Submission',
        },
    ];

    const events = [
        {
            title: '1400, Zoom - 2200561',
            date: '2023-11-05'
        },
        {
            title: '1400, SR3B - 2200562',
            date: '2023-11-10'
        },
        {
            title: '1200, SR2A - 2200563',
            date: '2023-11-15'
        },
        {
            title: '1400, Zoom - 2200564',
            date: '2023-11-15'
        }
    ];
    
    const pastConsultations = [
        {
            date: '15-10-2023',
            time: '14:00',
            student: 'Student Z',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '15-10-2023',
            time: '12:00',
            student: 'Student Y',
            venue: 'NYP@SIT SR2A',
            description: 'Past HCI Stuffs',
        },
        {
            date: '13-10-2023',
            time: '14:00',
            student: 'Student X',
            venue: 'NYP@SIT SR3B',
            description: 'Past HCI Stuffs',
        },
        {
            date: '12-10-2023',
            time: '14:00',
            student: 'Student W',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '10-10-2023',
            time: '14:00',
            student: 'Student V',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '08-10-2023',
            time: '12:00',
            student: 'Student U',
            venue: 'NYP@SIT SR2A',
            description: 'Past HCI Stuffs',
        },
        {
            date: '07-10-2023',
            time: '14:00',
            student: 'Student T',
            venue: 'NYP@SIT SR3B',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student S',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student R',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student Q',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student P',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student O',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student N',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student M',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student L',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student K',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student J',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student I',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student H',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student G',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student F',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
        {
            date: '06-10-2023',
            time: '14:00',
            student: 'Student E',
            venue: 'Zoom',
            description: 'Past HCI Stuffs',
        },
    ];
    

    const [email] = useState('');
    const [password] = useState('');

    const handleTabSelect = (index) => {
        setSelectedTabIndex(index);
    };
    const width = 400;
    const height = 400;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const openModal = () => {
        const modal = window.open('', 'Modal', `width=${width},height=${height},left=${left},top=${top}`);
        if (modal) {
          const modalContent = `
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; display: flex; flex-direction: column; align-items: center;">
            <img src="/MS_logo.png" alt="Logo" style="width: 100px; height: 100px; margin-bottom: 20px;" /> <!-- Add your logo image here -->
            <form id="loginForm" style="text-align: center;">
                <label style="display: block; margin-bottom: 10px;">Email:
                    <input type="text" id="emailInput" value="${email}" />
                </label>
                <label style="display: block; margin-bottom: 10px;">Password:
                    <input type="password" id="passwordInput" value="${password}" />
                </label>
                <button type="button" onclick="submitForm">Submit</button>
            </form>
        </div>
        `;
    
          modal.document.write(modalContent);
        }
    };
    const width2 = 600;
    const height2 = 400;
    const left2 = window.innerWidth / 2 - width2 / 2;
    const top2 = window.innerHeight / 2 - height2 / 2;
    const openConsultationModal = (event) => {
        const modal = window.open('', 'Modal', `width=${width2},height=${height2},left=${left2},top=${top2}`);
        if (modal) {
            const modalContent = `
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; background-color: #f7f7f7; padding-left: 50px; padding-right: 50px; border: 1px solid #ccc; border-radius: 10px;">
                <h2>Event Details</h2>
                <p><strong>Date:</strong> ${event.date}</p>
                <p><strong>Time:</strong> ${event.time}</p>
                <p><strong>Student:</strong> ${event.student}</p>
                <p><strong>Venue:</strong> ${event.venue}</p>
                <p><strong>Description:</strong> ${event.description}</p>
                <button style="margin-top: 20px; background-color: #888888; color: #fff; border: none; padding: 10px 20px; margin-bottom: 20px; border-radius: 5px; cursor: pointer;" type="button" onclick="window.close()">Close</button>
            </div>
            `;

            modal.document.write(modalContent);
        }
    }
      
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
  
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // You can access the selected file here
            console.log('Selected file:', file.name);
            setSelectedFile(file);
    
            // You can also upload the file to a server or process it as needed
            // For uploading to a server, you would typically use AJAX or the Fetch API.
        } else {
            console.log('No file selected');
        }
    };
    const handleImportBtn = () => { 
        fileInputRef.current.click();   
    };
    const handleSyncBtn = () => {
        openModal();
    };

    const handleLogout = () => {
        navigate('/logout');
    };

    const handleRowClick = (event) => {
        // Handle the click event for the row here
        openConsultationModal(event);
    };
    const tableRows = currentConsultation.map((event, index) => (
        <tr key={index} className={`row-clickable ${index % 2 === 0 ? "even" : "odd"}`} onClick={() => handleRowClick(event)}>
            <td>{event.date}</td>
            <td>{event.time}</td>
            <td>{event.student}</td>
            <td>{event.venue}</td>
        </tr>
    ));

    const [currentPage] = useState(1);
    const [pastPage, setpastPage] = useState(1);

    // Function to go to the previous page
    const goToPreviousPage = () => {
        if (pastPage > 1) {
            setpastPage(pastPage - 1);
        }
    };

    // Function to go to the next page
    const goToNextPage = () => {
        if (pastPage < Math.ceil(pastConsultations.length / tableRowsPerPage)) {
            setpastPage(pastPage + 1);
        }
    };
    const tableRowsPerPage = 8;
    const startIndex = (pastPage - 1) * tableRowsPerPage;
    const endIndex = startIndex + tableRowsPerPage; 

    const totalEntriesCurrent = currentConsultation.length;
    const totalPagesCurrent = Math.ceil(totalEntriesCurrent / tableRowsPerPage);
    const isLastPageNotFullyFilledCurrent = currentPage === totalPagesCurrent && totalEntriesCurrent % tableRowsPerPage !== 0;

    const totalEntries = pastConsultations.length;
    const totalPages = Math.ceil(totalEntries / tableRowsPerPage);
    const isLastPageNotFullyFilled = pastPage === totalPages && totalEntries % tableRowsPerPage !== 0;

    const tableRows2 = pastConsultations.slice(startIndex, endIndex).map((event, index) => (
        <tr key={index} className={`row-clickable ${index % 2 === 0 ? "even" : "odd"}`} onClick={() => handleRowClick(event)}>
            <td>{event.date}</td>
            <td>{event.time}</td>
            <td>{event.student}</td>
            <td>{event.venue}</td>
        </tr>
    ));
    // Calculate the number of empty rows to add to fill the last page
    const emptyRowsCountCurrent = isLastPageNotFullyFilledCurrent ? tableRowsPerPage - (totalEntriesCurrent % tableRowsPerPage) : 0;

    const emptyRowsCount = isLastPageNotFullyFilled ? tableRowsPerPage - (totalEntries % tableRowsPerPage) : 0;

    // Render the empty rows
    const emptyRowsCurrent = Array.from({ length: emptyRowsCountCurrent }, (_, index) => (
        <tr key={`emptyRow-${index}`} className={index % 2 === 0 ? "even" : "odd"}>
            <td colSpan="4">&nbsp;</td>
        </tr>
        ));
    const emptyRows = Array.from({ length: emptyRowsCount }, (_, index) => (
    <tr key={`emptyRow-${index}`} className={index % 2 === 0 ? "even" : "odd"}>
        <td colSpan="4">&nbsp;</td>
    </tr>
    ));

    return (
        <div>
            <div className="sub-header">
                <h1>Appointments Overview</h1>
            </div>
            <div className="content">
                <div className="navbar">
                    <div className="tabs">
                        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                            <TabList>
                                <Tab>Appointments</Tab>
                                <Tab>Calendar View</Tab>
                                <Tab>Past Records</Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
                {selectedTabIndex === 0 && (
                    <div className="table-container">
                    <h1 className='month'>Current Consultations</h1>
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Student</th>
                                    <th>Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                                {emptyRowsCurrent}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="left-aligned">
                                        <div className="navigation-controls">
                                            <p className="navigation-text">Showing {((currentPage - 1) * tableRowsPerPage) + 1} to {Math.min(currentPage * tableRowsPerPage, currentConsultation.length)} of {currentConsultation.length} entries</p>
                                            <button className="nav-btn-disabled" disabled>Previous</button>
                                            <button className="nav-btn-disabled" disabled>Next</button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                {selectedTabIndex === 1 && (
                    <div className="table-container">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            height="70vh"
                            events={events}
                            customButtons={{
                                ImportBtn: {
                                  text: 'Import Calendar',
                                  click: handleImportBtn,
                                },
                                SyncBtn: {
                                    text: 'Sync Calendar',
                                    click: handleSyncBtn,
                                }
                            }}
                            headerToolbar={{
                                left: 'title',
                                center: 'ImportBtn SyncBtn',
                                right: 'prev next today',
                            }}
                        />
                        <div className='modal'>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                    </div>
                )}
                {selectedTabIndex === 2 && (
                    <div className="table-container">
                    <h1 className='month'>Past Records</h1>
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Student</th>
                                    <th>Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows2}
                                {emptyRows}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="left-aligned">
                                        <div className="navigation-controls">
                                            <p className="navigation-text">Showing {((pastPage - 1) * tableRowsPerPage) + 1} to {Math.min(pastPage * tableRowsPerPage, pastConsultations.length)} of {pastConsultations.length} entries</p>
                                            <button className="nav-btn" onClick={goToPreviousPage}>Previous</button>
                                            <button className="nav-btn" onClick={goToNextPage}>Next</button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfessorHome;
