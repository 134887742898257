import React from 'react';

const CancellationConfirmationModal = ({ isOpen, onYes, onCancel }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Confirm Cancellation</h2>
        <p>Are you sure you want to cancel this booking?</p>
        <div className="modal-buttons">
          <button onClick={onYes}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default CancellationConfirmationModal;
