import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./StudentFAQ.css";

const StudentFAQ = () => {
    const navigate = useNavigate();

    const handleAddButtonClick = () => {
        // Implement the action you want to perform when the "+" button is clicked.
        // This could include opening a form, adding a new row to the table, etc.
    };

    const faqData = [
        {
            question: "What is the purpose of this website?",
            answer: "The SIT Resource Booking System (RBS) allows students to book school resources and consultation sessions with professors.",
        },
        {
            question: "How do I make a booking?",
            answer: "You may refer to the question mark (?) icon the top left of the navbar for a detailed booking guide."
        },
        // Add more as needed
    ];

    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/studenthome" className="header-button">Home</a>
                    <a href="/studentfaq" className="header-button">FAQ</a>
                    <p className="student-name">Student Y</p>
                    <div hred="/studentbooking" className="logout-button">
                        <button className="logout-button" onClick={handleAddButtonClick}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="faq-page">
                <h1>Frequently Asked Questions</h1>
                <ul className="faq-list">
                    {faqData.map((item, index) => (
                        <li key={index} className="faq-item">
                            <div className="question">{item.question}</div>
                            <div className="answer">{item.answer}</div>
                        </li>
                    ))}
                </ul>
                {/* <button className="back-button" onClick={() => navigate(-1)}>Back</button> */}
            </div>
        </div>
    );
};

export default StudentFAQ;