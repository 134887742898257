import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./ProfessorFAQ.css";

const ProfessorFAQ = () => {
    const navigate = useNavigate();
      
    const handleLogout = () => {
        navigate('/logout');
    };

    const faqData = [
        {
            question: "What is the purpose of this website?",
            answer: "The SIT Resource Booking System (RBS) allows students to book school resources and consultation sessions with professors.",
        },
        {
            question: "What are the features of the RBS for professors?",
            answer: "Professors can set their weekly consultation availability and import their calendars to better manage their work schedule.",
        },
        {
            question: "How do I set my weekly availability?",
            answer: "Go to 'Settings Management' on the navbar and select 'Manage Settings' to adjust your weekly availability.",
        },
        // Add more as needed
    ];

    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/professorhome" className="logo-button">
                        <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                    </a>
                    <a href="/professorhome" className="header-button">Home</a>
                    <span className="separator"></span>
                    <a href="/professorsettings" className="header-button">Settings Management</a>
                    <span className="separator"></span>
                    <a href="/professorfaq" className="header-button">FAQ</a>

                    <p className="professor-name">Professor X</p>
                    <div className="logout-btn">
                        <button class="logout-btn" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="faq-page">
                <h1>Frequently Asked Questions</h1>
                <ul className="faq-list">
                    {faqData.map((item, index) => (
                        <li key={index} className="faq-item">
                            <div className="question">{item.question}</div>
                            <div className="answer">{item.answer}</div>
                        </li>
                    ))}
                </ul>
                {/* <button className="back-button" onClick={() => navigate(-1)}>Back</button> */}
            </div>
        </div>
    );
};

export default ProfessorFAQ;