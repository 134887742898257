import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./ProfessorHome.css";

const ProfessorEdit = () => {
    const initialSettings = [
        {
          day: 'Monday',
          available: true,
          physicalConsultation: true,
          startTime: '12:00',
          endTime: '16:00',
        },
        {
          day: 'Tuesday',
          available: true,
          physicalConsultation: true,
          startTime: '13:00',
          endTime: '15:00',
        },
        {
          day: 'Wednesday',
          available: false,
          physicalConsultation: false,
        },
        {
          day: 'Thursday',
          available: true,
          physicalConsultation: true,
          startTime: '08:00',
          endTime: '16:00',
        },
        {
          day: 'Friday',
          available: true,
          physicalConsultation: true,
          startTime: '10:00',
          endTime: '16:00',
        },
    ];

    const [settings, setSettings] = useState(initialSettings);

    const handleInputChange = (day, field, value) => {
        // Update the settings when a form field changes
        setSettings((prevSettings) =>
          prevSettings.map((setting) =>
            setting.day === day ? { ...setting, [field]: value } : setting
          )
        );
        if (field === 'available' && !value) {
            // Clear "Start Time" and "End Time" and uncheck the "Physical Consultation" checkbox
            setSettings((prevSettings) =>
                prevSettings.map((setting) =>
                    setting.day === day ? { ...setting, startTime: '', endTime: '', physicalConsultation: false } : setting
                )
            );
        }
    };

    const navigate = useNavigate();
    const handleAddButtonClick = () => {
        // Implement the action you want to perform when the "+" button is clicked.
        // This could include opening a form, adding a new row to the table, etc.
    };

    const createNewSettings = () => {
        navigate('/professorsettings2');
    };

    const onCancel = () => {
        navigate(`/professorsettings`);
    };

    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/professorhome" className="header-button">Home</a>
                    <a href="/professorsettings" className="header-button">Booking Management</a>
                    <a href="link-to-faq-page" className="header-button">FAQ</a>
                    <p className="professor-name">Professor X</p>
                    <div className="logout-button">
                        <button className="logout-button" onClick={handleAddButtonClick}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="sub-header">
                <h1>Manage Settings</h1>
            </div>
            
            <div className="centered-form">
                <form>
                    <table>
                    <thead>
                        <tr>
                        <th>Day</th>
                        <th>Availability</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Physical Consultation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {settings.map((setting) => (
                            <tr key={setting.day}>
                                <td>{setting.day}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={setting.available}
                                        onChange={(e) =>
                                            handleInputChange(setting.day, 'available', e.target.checked)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={setting.startTime}
                                        disabled={!setting.available} // Disable if not available
                                        onChange={(e) =>
                                            handleInputChange(setting.day, 'startTime', e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={setting.endTime}
                                        disabled={!setting.available} // Disable if not available
                                        onChange={(e) =>
                                            handleInputChange(setting.day, 'endTime', e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={setting.physicalConsultation}
                                        disabled={!setting.available} // Disable if not available
                                        onChange={(e) =>
                                            handleInputChange(setting.day, 'physicalConsultation', e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                    <div>
                    <button onClick={createNewSettings}>Save</button>
                    <button onClick={onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProfessorEdit;