import React, { useState } from "react";
import { Navigate, Outlet, Link, useLocation } from "react-router-dom";
import { useAuth } from "./Authentication";
import jwt_decode from "jwt-decode";
import Modal from "./Modal";
import { FaCircleQuestion } from "react-icons/fa6";
import "./ProtectedLayout.css";
import Help from "../pages/Help"; // For students
import Help2 from "../pages/Help2"; // For professors
import { Button } from "@mui/material";
import { IoLogOutOutline } from "react-icons/io5";

export const ProtectedLayout = () => {
  const { authed } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeProperty, setActiveProperty] = useState(useLocation().pathname); // Initialize with null

  const tokenFromSession = sessionStorage.getItem("authToken");
  const tokenFromLocal = localStorage.getItem("authToken");
  const token = tokenFromSession || tokenFromLocal; // Use the first available token
  
  const handlePropertyClick = (property) => {
    setActiveProperty(property);
  };
  
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (!authed || !token) {
    return <Navigate to="/" replace />;
  } else {
    try {
      const decodedToken = jwt_decode(token);
      const userRole = decodedToken.role;
      console.log(userRole);
      if (userRole === "Professor") {
        return (
          <>
          <div className="header">
            <nav className="header-content">
              <div className="left-side">
                  <a href="/professorhome" className="logo-button">
                    <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                  </a>

                <div style={{display:"flex", justifyContent:"flex-start", alignContent:"center", verticalAlign:"center"}}>
                  <Link to="/professorhome" className={`header-button ${activeProperty === "/professorhome" ? "active" : ""}`} onClick={() => handlePropertyClick("/professorhome")}>Home</Link>
                  <span className="separator"></span>
                  <Link to="/professorsettings" className={`header-button ${activeProperty === "/professorsettings" ? "active" : ""}`} onClick={() => handlePropertyClick("/professorsettings")}>Settings Management</Link>
                  <span className="separator"></span>
                  <Link to="/professorfaq" className={`header-button ${activeProperty === "/professorfaq" ? "active" : ""}`} onClick={() => handlePropertyClick("/professorfaq")}>FAQ</Link>
                  <span className="separator"></span>
                </div>
              </div>
              <div className="right-side">
                <div style={{display:"flex", justifyContent:"flex-start", alignContent:"center", verticalAlign:"center", gap:"10px"}}>
                  <p className="professor-name">Professor X</p>
                  <button type="button" id="modal-button" onClick={openModal} style={{margin:0}}><FaCircleQuestion /></button>
                  <Button variant="contained" startIcon={<IoLogOutOutline />}>
                    <Link to="/logout" style={{textDecoration:"none", color:"white"}}>Log Out</Link>
                  </Button>
                </div>
              </div>
            </nav>
          </div>
          <Outlet />
          <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2>Professor Guide to scheduling bookings with Students</h2>
          <p>1. Go to settings to update your availability and preferences in advance.</p>
          <p>2. A dialog will show up if availability has been successfully.</p>
          <p>3. Check the upcoming bookings from the dashboard for the location and time of the approved appointments.</p>
          <br />
          <Help2 />
          </Modal>
          </>
        );
      } else if (userRole === "Student") {
        return (
          <>
          <div className="header">
            <nav className="header-content">
              <div className="left-side">
                <a href="/studenthome" className="logo-button">
                  <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                </a>
                <div style={{display:"flex", justifyContent:"flex-start", alignContent:"center", verticalAlign:"center"}}>
                  <Link to="/studenthome" className={`header-button ${activeProperty === "/studenthome" ? "active" : ""}`} onClick={() => handlePropertyClick("/studenthome")}>Home</Link>
                  <span className="separator"></span>
                  <Link to="/studentfaq" className={`header-button ${activeProperty === "/studentfaq" ? "active" : ""}`} onClick={() => handlePropertyClick("/studentfaq")}>FAQ</Link>
                  <span className="separator"></span>
                </div>
              </div>
              <div className="right-side">
                <div style={{display:"flex", justifyContent:"flex-start", alignContent:"center", verticalAlign:"center", gap:"10px"}}>
                  <p className="student-name">Student Y</p>
                  <button type="button" id="modal-button" onClick={openModal} style={{margin:0}}><FaCircleQuestion /></button>
                  <Button variant="contained" startIcon={<IoLogOutOutline />}>
                    <Link to="/logout" style={{textDecoration:"none", color:"white"}}>Log Out</Link>
                  </Button>
                </div>
              </div>
            </nav>
          </div>            
          <Outlet />
          <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2>Student Guide to book a Professor</h2>
          <p>1. Create a consultation through New Booking at the Home page.</p>
          <p>2. Choose the professor, find an available date and time.</p>
          <p>3. A dialog will show up if the Booking is confirmed.</p>
          <p>4. Check your inbox for a confirmation email, check your spam folder.</p>
          <p>5. Wait for up to 2 days for the professor to confirm the appointment.</p>
          <p>6. Check the upcoming bookings from the dashboard for the location and time of the approved appointments.</p>
          <br />
          <Help />
          </Modal>
          </>
        );
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      // Handle the case where token decoding fails
    }
  }
};
