import React, { useState } from 'react';
import './Resource.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import BookingConfirmation from './BookingSuccess'; // Import the confirmation component

const Facility = () => {
  const [selectedResource, setSelectedResource] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const navigate = useNavigate();

  const handleResourceChange = (event) => {
    setSelectedResource(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleConsultationClick = () => {
    navigate('/studentsettings');
  };

  const handleClearForm = () => {
    // Reset the form fields to their initial values
    setSelectedResource('');
    setSelectedSize('');
    setSelectedReason('');
    setSelectedTime('');
    setSelectedDate('');
  };

  const cancelForm = () => {
    navigate('/studenthome');
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting and refreshing the page

    // Handle form submission and move to the next page. Send props over
    console.log('Form submitted with the following data:');
    console.log('Facility:', selectedResource);
    console.log('Size: ', selectedSize);
    console.log('Reason: ', selectedReason);
    console.log('Time:', selectedTime);
    console.log('Date:', selectedDate);

    // Show the confirmation page
    setShowConfirmation(true);

  };

  return (
    <div>
      {/* <div className="header">
        <div className="header-content">
          <a href="/studenthome" className="header-button">
            Home
          </a>
          <a href="studentfaq" className="header-button">
            FAQ
          </a>
          <p className="student-name">Student Y</p>
          <div className="logout-button">
            <button className="logout-button" onClick={handleConsultationClick}>
              Logout
            </button>
          </div>
        </div>
      </div> */}
      <h1 class="sub-header">Resource Booking</h1>
      {showConfirmation ? (
        <BookingConfirmation
          selectedResource={selectedResource}
          selectedSize={selectedSize}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Resource Type:</label>
            <select value={selectedResource} onChange={handleResourceChange} required>
              <option value="">Select a Resource Type</option>
              <option value="discussion">Discussion Room</option>
              <option value="lab">Laboratory</option>
              <option value="lecture">Lecture Theatre</option>
              <option value="seminar">Seminar Room</option>
            </select>
          </div>
          <div>
            <label>Number of People:</label>
            <input type='text' value={selectedSize} onChange={handleSizeChange}  required/>
          </div>
          <div>
            <label>Booking Reason:</label>
            <input type='text' value={selectedReason} onChange={handleReasonChange}  required/>
          </div>
          <div>
            <label>Date:</label>
            <input type="date" value={selectedDate} onChange={handleDateChange}  required/>
          </div>
          <div>
            <label>Time:</label>
            <input type="time" value={selectedTime} onChange={handleTimeChange}  required/>
          </div>
  
          <br/>
          <div className='button-container' style={{flexDirection:"row-reverse"}}>
            <button type="submit">Book</button>
            <button type="button" onClick={handleClearForm}>
              Clear Form
            </button>

            <button type="button" id="close" onClick={cancelForm}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Facility;
