import React, { useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

// import "./ProfessorHome.css";

const ProfessorHome2 = () => {
    const navigate = useNavigate();
    const [email] = useState('');
    const [password] = useState('');

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const handleTabSelect = (index) => {
        setSelectedTabIndex(index);
    };
    const width = 400;
    const height = 400;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const openModal = () => {
        const modal = window.open('', 'Modal', `width=${width},height=${height},left=${left},top=${top}`);
        if (modal) {
            const modalContent = `
                <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; display: flex; flex-direction: column; align-items: center;">
                <img src="/MS_logo.png" alt="Logo" style="width: 100px; height: 100px; margin-bottom: 20px;" /> <!-- Add your logo image here -->
                <form id="loginForm" style="text-align: center;">
                    <label style="display: block; margin-bottom: 10px;">Email:
                    <input type="text" id="emailInput" value="${email}" />
                    </label>
                    <label style="display: block; margin-bottom: 10px;">Password:
                    <input type="password" id="passwordInput" value="${password}" />
                    </label>
                    <button type="button" onclick="submitForm">Submit</button>
                </form>
                </div>
            `;
    
          modal.document.write(modalContent);
        }
    };
      
    const handleLogout = () => {
        navigate('/logout');
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
  
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // You can access the selected file here
            console.log('Selected file:', file.name);
            setSelectedFile(file);
    
            // You can also upload the file to a server or process it as needed
            // For uploading to a server, you would typically use AJAX or the Fetch API.
        } else {
            console.log('No file selected');
        }
    };
    const handleImportBtn = () => { 
        fileInputRef.current.click();   
    };
    const handleSyncBtn = () => {
        openModal();
    };

    const tableRowsPerPage = 8;
    const pastConsultations = [];
    const tableRows = pastConsultations.map((event, index) => (
        <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
            <td>{event.date}</td>
            <td>{event.time}</td>
            <td>{event.student}</td>
            <td>{event.venue}</td>
        </tr>
      ));
    // Add empty rows to reach the desired total row count
    for (let i = 0; i < tableRowsPerPage; i++) {
        if (i === 0) {
            // Render the "No Current Bookings" row for the first row
            tableRows.push(
                <tr key={`row-${i}`} className={i % 2 === 0 ? "even" : "odd"}>
                <td colSpan="4" className="no-bookings">No Bookings</td>
                </tr>
            );
        } else {
            // Render empty rows for the rest of the rows
            tableRows.push(
                <tr key={`row-${i}`} className={i % 2 === 0 ? "even" : "odd"}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                </tr>
            );
        }
    }

    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/professorhome" className="logo-button">
                        <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                    </a>
                    <a href="/professorhome" className="header-button">Home</a>
                    <span className="separator"></span>
                    <a href="/professorsettings" className="header-button">Settings Management</a>
                    <span className="separator"></span>
                    <a href="/professorfaq" className="header-button">FAQ</a>

                    <p className="professor-name">Professor X</p>
                    <div className="logout-btn">
                        <button class="logout-btn" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="sub-header">
                <h1>Appointments Overview</h1>
            </div>
            <div className="content">
                <div className="navbar">
                    <div className="tabs">
                        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                            <TabList>
                                <Tab>Appointments</Tab>
                                <Tab>Calendar View</Tab>
                                <Tab>Past Records</Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
                {selectedTabIndex === 0 && (
                    <div className="table-container">
                    <h1 className='month'>Current Consultations</h1>
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Student</th>
                                    <th>Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="left-aligned">
                                        <div className="navigation-controls">
                                            <p className="navigation-text">Showing 0 to 0 of 0 entries</p>
                                            <button className="nav-btn-disabled" disabled>Previous</button>
                                            <button className="nav-btn-disabled" disabled>Next</button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                {selectedTabIndex === 1 && (
                    <div className="table-container">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            height="70vh"
                            customButtons={{
                                ImportBtn: {
                                  text: 'Import Calendar',
                                  click: handleImportBtn,
                                },
                                SyncBtn: {
                                    text: 'Sync Calendar',
                                    click: handleSyncBtn,
                                }
                            }}
                            headerToolbar={{
                                left: 'title',
                                center: 'ImportBtn SyncBtn',
                                right: 'prev next today',
                            }}
                        />
                        <div className='modal'>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                    </div>
                )}
                {selectedTabIndex === 2 && (
                    <div className="table-container">
                    <h1 className='month'>Past Records</h1>
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Student</th>
                                    <th>Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="left-aligned">
                                        <div className="navigation-controls">
                                            <p className="navigation-text">Showing 0 to 0 of 0 entries</p>
                                            <button className="nav-btn-disabled" disabled>Previous</button>
                                            <button className="nav-btn-disabled" disabled>Next</button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfessorHome2;
