import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoClose, IoCheckmark, IoRemoveCircleOutline } from "react-icons/io5";

import "./ProfessorHome.css";
import { Alert } from '@mui/material';

const ProfessorEdit = () => {
    const initialAvailabilitySettings = [
        {
            day: 'Monday',
            available: true,
            physicalConsultation: true,
            startTime: '12:00',
            endTime: '16:00',
        },
        {
            day: 'Tuesday',
            available: true,
            physicalConsultation: true,
            startTime: '13:00',
            endTime: '15:00',
        },
        {
            day: 'Wednesday',
            available: false,
            physicalConsultation: false,
        },
        {
            day: 'Thursday',
            available: true,
            physicalConsultation: true,
            startTime: '08:00',
            endTime: '16:00',
        },
        {
            day: 'Friday',
            available: true,
            physicalConsultation: true,
            startTime: '10:00',
            endTime: '16:00',
        },
    ];
    const [availabilitySettings, setAvailabilitySettings] = useState(initialAvailabilitySettings);
    const [isEditing, setIsEditing] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    const handleDeleteRow = (index) => {
        const updatedSettings = [...availabilitySettings];
        updatedSettings.splice(index, 1);
        setAvailabilitySettings(updatedSettings);
    };
    const handleAddRow = () => {
        const newRow = {
            day: '', // Provide initial values for the new row
            available: true,
            physicalConsultation: true,
            startTime: '09:00', // Initial consultation hours for the new row
            endTime: '16:00',
        };
    
        setAvailabilitySettings([...availabilitySettings, newRow]);
    };
    const timeArray = [];
    const startTime = 9 * 60; // 9 AM in minutes
    const endTime = 18 * 60; // 6 PM in minutes
    const interval = 30; // 30 minutes
    
    for (let i = startTime; i <= endTime; i += interval) {
        const hours = Math.floor(i / 60);
        const minutes = i % 60;
        const timeSlot = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        timeArray.push(timeSlot);
    }
    const toggleEditing = () => {
        if (isEditing === true  ){
            setFeedbackMessage("Settings has been updated.");
        }
        setIsEditing(!isEditing);
    };

    const resetSettings = () => {
        navigate('/professorsettings');
    };
    
    const handleCheckboxChange = (index) => {
        const updatedSettings = [...availabilitySettings];
        updatedSettings[index].available = !updatedSettings[index].available;
        setAvailabilitySettings(updatedSettings);
    };
    const handleCheckboxChange2 = (index) => {
        const updatedSettings = [...availabilitySettings];
        updatedSettings[index].physicalConsultation = !updatedSettings[index].physicalConsultation;
        setAvailabilitySettings(updatedSettings);
    };

    const handleInputChange = (index, field, value) => {
        const updatedSettings = [...availabilitySettings];
        updatedSettings[index][field] = value;
        setAvailabilitySettings(updatedSettings);
    };
    const navigate = useNavigate();
    const handleLogout = () => {
        navigate('/logout');
    };

    return (
        <div>
            {/* <div className="header">
                <div className="header-content">
                    <a href="/professorhome" className="logo-button">
                        <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
                    </a>
                    <a href="/professorhome" className="header-button">Home</a>
                    <span className="separator"></span>
                    <a href="/professorsettings" className="header-button">Settings Management</a>
                    <span className="separator"></span>
                    <a href="professorfaq" className="header-button">FAQ</a>

                    <p className="professor-name">Professor X</p>
                    <div className="logout-btn">
                        <button class="logout-btn" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div> */}
            <div className="sub-header" style={{display:"flex", justifyContent:"space-between", margin: "10px 20px"}}>
                <h1 style={{width: "80%" }}>Manage Settings</h1>
                <div className='button-container' style={{display:"flex", flexDirection:"row", flexWrap:"nowrap", justifyContent:"flex-end"}}>
                    {isEditing && (
                    <div className='reset-btn'>
                            <button className="reset-btn" onClick={resetSettings}>Reset Settings</button>
                    </div>
                    )}
                    <div className="edit-btn">
                        <button className="edit-btn" onClick={toggleEditing}>{isEditing ? 'Save Changes' : 'Manage Settings'}</button>
                    </div>
                </div>
            </div>
            {feedbackMessage && (
                <Alert severity="success">{feedbackMessage}</Alert>
            )}
            <div className="content">
                <div className="form-container">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th style={{ width: '150px'}}>{isEditing ? 'Enable?' : 'Availability Status'}</th>
                                <th>Settings Name</th>
                                <th>Consultation Hours</th>
                                <th style={{ width: '200px'}}>Physical Availability?</th>
                                {isEditing && <th style={{ width: '100px' }}>Remove</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {availabilitySettings.map((setting, index) => (
                                <tr key={index}>
                                <td>
                                    {isEditing ? (
                                    <input
                                        type="checkbox"
                                        checked={setting.available}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    ) : (
                                    setting.available ? <span><IoCheckmark style={{stroke:"black", fontSize:"24px"}}/></span> : <IoClose style={{stroke:"black", fontSize:"24px"}}/>
                                    )}
                                </td>
                                <td>
                                    {isEditing && setting.available ? (
                                    <input
                                        type="text"
                                        value={setting.day}
                                        onChange={(e) => handleInputChange(index, 'day', e.target.value)}
                                        style={{ textAlign: 'center' }}
                                    />
                                    ) : (
                                    setting.day
                                    )}
                                </td>
                                <td>
                                    {isEditing && setting.available ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <select>
                                            {timeArray.map((timeSlot, index) => (
                                            <option key={index} value={timeSlot}>
                                                {timeSlot}
                                            </option>
                                            ))}
                                        </select>
                                        <select>
                                            {timeArray.map((timeSlot, index) => (
                                            <option key={index} value={timeSlot}>
                                                {timeSlot}
                                            </option>
                                            ))}
                                        </select>
                                        </div>
                                    ) : setting.available ? (
                                        `${setting.startTime} to ${setting.endTime}`
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {isEditing && setting.available ? (
                                    <input
                                        type="checkbox"
                                        checked={setting.physicalConsultation}
                                        onChange={() => handleCheckboxChange2(index)}
                                    />
                                    ) : (
                                    setting.physicalConsultation ? (
                                        <span><IoCheckmark style={{stroke:"black", fontSize:"24px"}}/></span>
                                    ) : (
                                        <span><IoClose style={{stroke:"black", fontSize:"24px"}}/></span>
                                    )
                                    )}
                                </td>
                                    {isEditing && (
                                <td>
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleDeleteRow(index); }} >
                                        <IoRemoveCircleOutline style={{stroke:"red", fontSize:"24px"}}/>
                                    </a>
                                </td>
                                    )}
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                    {isEditing && (
                                <td colSpan="5">
                                        <button onClick={handleAddRow} className="add-btn">Add</button>
                                </td>
                                    )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProfessorEdit;