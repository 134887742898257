import React from 'react';
import { Navigate, Outlet, Link } from "react-router-dom";
import "./ProfessorFAQ.css"

function Help2() { // For professors
  return (
    <div>
      <h2>Problems Regarding Your Booking</h2>
      <p>If you are facing issues regarding your booking, do check out our  <Link to="/professorfaq">FAQ.</Link></p>
      <p>If the matter persists, please contact the SIT IT helpdesk at <b>ITsupport@sit.singaporetech.edu.sg.</b></p>
    </div>
  );
}

export default Help2;

