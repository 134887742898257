import { useEffect } from "react";
import { useAuth } from "../components/Authentication";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      // Perform a logout action if authenticated
      await logout();

      // Navigate to the root ("/") route with a success message in the state
      navigate("/", {
        state: { message: "You have been logged out." },
        replace: true,
      });
    };

    // Call the asynchronous function
    performLogout();
  }, [logout, navigate]);

  return null; // Return null to avoid rendering anything
}
