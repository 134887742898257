import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import BookingModal from '../components/BookingModal';
import "./StudentHome.css";
import CancellationConfirmationModal from './CancellationConfirmationModal';
import { Alert } from '@mui/material';


const StudentHome2 = () => {
  const navigate = useNavigate();
  
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("You have cancelled Consultation successfully");

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleResourceClick = () => {
    navigate('/resource');
  };

  const handleConsultationClick = () => {
    navigate('/consultation');
  };

  const handleOpenCancellationModal = () => {
    setIsCancellationModalOpen(true);
  };
  
  const handleCloseCancellationModal = () => {
    setIsCancellationModalOpen(false);
  };

  const handleYesClick = () => {
    navigate('/StudentHome2');
  };

  
  const events = [
    {
        title: '14:00, SIT@NYP SR3A, Project meeting',
        date: '2023-11-01'
    },
  ];

  return (
    <div>
      {/* 
      <div class="header">
        <div class="header-content">
            <a href="/professorhome" className="logo-button">
                <img src="/SIT_logo.png" alt="Your Logo" className="logo-image"/>
            </a>
            <a href="/studenthome" class="header-button">Home</a>
            <span className="separator"></span>
            <a href="/studentfaq" class="header-button">FAQ</a>
            <p class="student-name">Student Y</p>
            <div class="logout-button">
                <button class="logout-button" onClick={handleLogout}>Logout</button>
            </div>
        </div>
      </div>
      */}
      <div class="sub-header">
          <h1>My Bookings</h1>
          <button className="new-booking-button" onClick={handleOpenModal}>
            New Booking
          </button>
      </div>
      <div className="content">
        <div className="navbar">
            <div className="tabs">
                <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                  <TabList>
                    <Tab>Upcoming</Tab>
                    <Tab>Pending</Tab>
                    <Tab>Past</Tab>
                    <Tab>Calendar View</Tab>
                  </TabList>
                </Tabs>
            </div>
        </div>
        {selectedTabIndex === 0 && (
          <div className="table-container">
            <h1 className='month'>Upcoming Bookings</h1>
            <Alert severity="success">{feedbackMessage}</Alert>
            <br></br>
            <table className="custom-table">
              <thead>
                  <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Venue</th>
                      <th>Purpose</th>
                      <th>Professor</th>
                      <th>Status</th>
                      <th>Action</th>
                  </tr>
              </thead>
              <tbody>
                <tr className="even">
                  <td>2023-11-01</td>
                  <td>14:00</td>
                  <td>Facility</td>
                  <td>SIT@NYP SR3A</td>
                  <td>Project meeting</td>
                  <td>-</td>
                  <td>Confirmed</td>
                  <td>
                    <button className="delete-button" onClick={handleOpenCancellationModal}>Cancel</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {selectedTabIndex === 1 && (
          <div className="table-container">
            <h1 className='month'>Pending Bookings</h1>
            <table className="custom-table">
              <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Type</th>
                <th>Venue</th>
                <th>Purpose</th>
                <th>Professor</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="8">No Pending Bookings</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {selectedTabIndex === 2 && (
          <div className="table-container">
            <h1 className='month'>Previous Bookings</h1>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Type</th>
                  <th>Venue</th>
                  <th>Purpose</th>
                  <th>Professor</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="7">No Past Bookings</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {selectedTabIndex === 3 && (
          <div className="table-container">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              height="70vh"
              events={events}
              headerToolbar={{
                left: 'title',
                right: 'prev next today',
            }}
            />
          </div>
        )}
      </div>
      <BookingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        facilityClick={handleResourceClick}
        equipmentClick={handleResourceClick}
        consultClick={handleConsultationClick}
      />
      <CancellationConfirmationModal
        isOpen={isCancellationModalOpen}
        onCancel={handleCloseCancellationModal}
        onYes={handleYesClick}
      />    
    </div>
  );
};

export default StudentHome2;
