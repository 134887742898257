import React from 'react';
import "./BookingModal.css";

const BookingModal = ({ isOpen, onClose, facilityClick, equipmentClick, consultClick }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="bookingmodal-overlay">
      <div className="bookingmodal">
        <h2>Choose Booking Type</h2>
        <div className="bookingmodal-button-container">  
          <button onClick={facilityClick}>Resource</button>
          <button onClick={consultClick}>Consultation</button>
          <button onClick={onClose} id="close">Close</button>
        </div>
        
      </div>
    </div>
  );
};

export default BookingModal;
