import React, { createContext, useContext, useState, useEffect } from "react";
import "./Authentication.css";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous authentication check (e.g., making an API request)
    setTimeout(() => {
      // Check for the presence of the authentication token here (e.g., a token stored in localStorage)
      const tokenFromSession = sessionStorage.getItem('authToken');
      const tokenFromLocal = localStorage.getItem('authToken');
      const token = tokenFromSession || tokenFromLocal; // Use the first available token
          
      if (token) {
        setAuthed(true);
      }
      setLoading(false);
    }, 500); // Simulate a half-second delay for the check
  }, []);

  const login = (rememberMe, token) => {
    // After a successful login, store the token
    if (rememberMe) {
      localStorage.setItem("authToken", token);
    } else {
      sessionStorage.setItem("authToken", token);
    }
    setAuthed(true);
  };

  const logout = () => {
    // Remove the token on logout
    sessionStorage.removeItem("authToken");
    localStorage.removeItem("authToken");
    setAuthed(false);
  };

  return (
    <AuthContext.Provider value={{ authed, login, logout }}>
      {!loading ? (
        children
      ) : (
        <div className="App">
          <header className="App-header">
            {/* <h3 className="App-title">Loading...</h3> */}
          </header>
        </div>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
