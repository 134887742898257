import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BookingSuccess.css'; // Import the CSS file for BookingConfirmation

const BookingConfirmation = ({ selectedProfessor, selectedMethod, selectedDay, selectedTime }) => {
  const navigate = useNavigate();

  return (
    <div className="booking-confirmation-container">
      <h1 className="booking-confirmation-header">Booking Confirmation</h1>
      <p>Your booking has been confirmed with the following details:</p>
      <p className="booking-details"><strong>Professor:</strong> {selectedProfessor}</p>
      <p className="booking-details"><strong>Method:</strong> {selectedMethod}</p>
      <p className="booking-details"><strong>Day:</strong> {selectedDay}</p>
      <p className="booking-details"><strong>Time:</strong> {selectedTime}</p>

      <button className="back-button" onClick={() => navigate('/studenthome')}>Back to Home</button>
    </div>
  );
};

export default BookingConfirmation;
